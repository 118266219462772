@import url(https://fonts.googleapis.com/css?family=Playfair+Display|Roboto+Slab&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Roboto Slab';
}

body {
  margin: 0;
  padding: 0;
  min-width: 320px;
}

.container {
  max-width: 900px;
  margin: 20px auto;
}

.crypto-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #00000012;
  margin-bottom: 8px;
  padding: 10px 50px;
  border-radius: 11px;
  box-sizing: border-box;
  box-shadow: -1px 2px 0px 0px rgba(0, 0, 0, 0.17);
  background: #da22ff; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#9733ee),
    to(#da22ff)
  );
  background: linear-gradient(
    to right,
    #9733ee,
    #da22ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.image {
  width: 25px;
  max-height: 25px;
  text-align: center;
}
.content {
  width: 100%;
}

.cypto-details {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr;
  color: white;
}

.cypto-details {
  text-align: center;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

h1 {
  text-align: center;
  font-size: 50px;
  /* font-family: 'Playfair Display'; */
  text-transform: capitalize;
}

.bold {
  font-weight: bolder;
}

p {
  margin: 8px;
}

.priceWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceWrapper p {
  min-width: 60px;
}

.fa-angle-double-up {
  color: #06dc06;
}

.fa-angle-double-down {
  color: red;
}

@media only screen and (max-width: 400px) {
  .crypto-item {
    padding: 10px 5px;
  }

  h1 {
    font-size: 36px;
  }

  .cryptoID {
    display: none;
  }

  body {
    margin: 0;
    padding: 5px;
  }

  p {
    font-size: 12px;
  }

  .cypto-details {
    width: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 0.5fr 1fr 1fr 1fr;
    grid-template-columns: 0.5fr 1fr 1fr 1fr;
  }

  a {
    font-size: 11px;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    margin: 0;
    padding: 5px;
    box-sizing: border-box;
  }

  p {
    font-size: 14px;
    margin: 4px;
  }

  .crypto-item {
    padding: 10px;
  }
  a {
    font-size: 12px;
  }
}

.nomics {
  border-bottom: 1px solid #a0f;
  text-decoration: none;
  color: black;
  line-height: 70px;
}
.nomics:visited {
  color: black;
}

.nomics:hover {
  color: #a0f;
}

